<template>
	<v-card>
		<s-toolbar label="Lista de Pallet" dark color="#8e8f91"></s-toolbar>
		<v-container>
			<v-row>
				<v-col cols="4">
					<s-date 
						label="Fecha Inicio"
						v-model="filter.DateBegin"
							
					></s-date>
				</v-col>
				<v-col cols="4">
					<s-date 
						label="Fecha Fin"
						v-model="filter.DateEnd"
							
					></s-date>
				</v-col>
			</v-row>
			<v-row >
				<v-col cols="12">
							<s-toolbar
							dark
							:color="'#BAB6B5'"
							style="display: flex"							 
							transferir									 
							@transferir="tranfer()"
							print
							@print="printQR()"
						></s-toolbar>
						<v-data-table
							:items="itemsSendEnd"
							item-key="LlpID"
							dense
							:headers="headerSendEnd"
							:items-per-page="15"									 								
							disable-sort
							group-by="IDChangeFsma"								
							show-select
							v-model="selected"
						> 		
							<template v-slot:item.separator="row">
								<v-chip
									small
									dark											 
									color="warning"
								>
									_
								</v-chip>
							</template>
								<template v-slot:item.LlpID="row">
								<v-chip
									small
									dark											 
									color="success"
								>
									{{row.item.LlpID}} 
								</v-chip>
							</template>
							<template v-slot:item.LlpIDm="row">
								<v-chip
									small
									dark											 
									color="success"
								>
									{{row.item.LlpIDm}} 
								</v-chip>
							</template>
						</v-data-table>
					</v-col>
			</v-row>
			<v-row justify="center" cols="12" style="display: none">
				<v-col cols="12" v-for="item in selected">
					<qr-code
						:text="
							item.LlpID +
								', Jabas: ' +
								item.LlpQuantityJaba +
								',' +
								item.TypeCaliberName +
								',' +
								item.TypeCultiveName +
								',' +
								item.VrtName +
								',' +
								item.TypeDestinyName +
								',' +
								item.TypeDestiny
						"
						error-level="H"
						hidden
					>
					</qr-code
					><br />
				</v-col>
			</v-row>
		</v-container>
	</v-card>
</template>

<script>
    import _sSupplyPalletMirror from "@/services/FreshProduction/PrfFsmaChangeService"; 
	import _sLotProdCalibratedPrintQRService from "../../../services/FreshProduction/PrfLotProductionCalibratedQRService";
	 

    import qrCode from "vue-qr-generator";

    export default {

        components: { qrCode },
        
        data: () => ({
			filter: {},
			selectedItemsSend: [],
			selectedItemsSendTwo: [],
            selected: [],
            itemsSendEnd: [],
            headerSendEnd: [
				/*{ text: "Tranferir", value: "Tranfer" },*/

				{ text: "Fecha Cambio", value: "SecCreate" }, 
				{ text: "Tarima", value: "ScfCode" }, 
				{ text: "Id Pallet", value: "LlpID" },
				{ text: "Lote Prod", value: "RcfID" },
				{ text: "Certificaciones", value: "Certifications" },
				{ text: "Cal", value: "TypeCaliberName" },
				{ text: "# JAB", value: "LlpQuantityJaba" },
				{ text: "Peso (KG)", value: "WeightAverageParihuela" },
				{ text: "Variedad", value: "VrtName" },
			],
        }),
        watch: {
            selected(){
                this.rowSelected(this.selected)
            }
        },
        methods: {
            rowSelected(val) {
				if (val !== undefined) {
					this.selected = val;

					if(this.selected.length > 0) {
						console.log("selected", this.selected);

						_sLotProdCalibratedPrintQRService.GetDetailQR(this.selected[0].LlpID, this.$fun.getUserID()).then(resp => {
							if (resp.status == 200) {
								this.itemsDetail = resp.data;
								this.WbbNetWeight = 0;
								this.itemsDetail.forEach(element => {
									this.WbbNetWeight += element.WbbNetWeight
								});

								console.log("itemsDetail", this.itemsDetail);
							}
						});
					}
					
				}
			},
            printQR() {
				var imgAll = document.querySelector("img");
				
				var daten = new Date().toLocaleString();

                console.log("this.selected[0]", this.selected[0]);
               
				let ScfCode = "";
				if (this.selected[0].ScfCode != "" && this.selected[0].ScfCode != null) {
					ScfCode = "<h2 style='margin: 0px'><b>Tarima: " + this.selected[0].ScfCode + "</b></h2>";
				}

				var Pagelink = "about:blank";
				var pwa = window.open(Pagelink, "_new");
				pwa.document.open();
				pwa.document.write(
					"<html><head><scri" +
						"pt>function step1(){\n" +
						"setTimeout('step2()', 10);}\n" +
						"function step2(){window.print();window.close()}\n" +
						"</scri" +
						"pt><style media='print'>  @media print  { @page { margin-left: 4cm; {} </style></head><body onload='step1()'> \n" +
						"<div class='col col-12 justify-center'>",
				);

				
				/* imgAll.forEach(element => { */
					pwa.document.write(
						"<div class='col col-4' style='margin: 20px 20px 20px 20px;'><img alt='Scan me!' style='display: block;'  src='" +
							imgAll.currentSrc +
							"' /></div>",
					);

					pwa.document.write("<div ><table border='0'>");
					pwa.document.write(
								"<tr><td colspan='4'>Datos Generales: " + 
								"<tr>" +
								"<td>" + "<b>"+this.selected[0].LlpID + "</b>"+
								"</td><td> || " + "<b>"+this.selected[0].TypeCultiveName + "</b>"+
								"</td><td> || " + "<b>"+this.selected[0].VrtName + "</b>"+
								"</td><td> || " + "<b>"+this.selected[0].TypeCropName + "</b>"+
								"</td><td> || " + "<b>"+this.selected[0].TypeDestinyName + "</b>"+
								"</td><td> || " + "<b>"+this.selected[0].TypeCaliberName + "</b>"+
								/* "</td><td> || " + "<b>" + this.WbbNetWeight.toFixed(2)+"</b>"+ */
								" </td></tr></table></div>"   +
								"" + ScfCode + ""
					);
					pwa.document.write("");

					this.itemsDetail.forEach(detail => {
							
							pwa.document.write("<div ><table border='0'>");

							pwa.document.write(
								"<tr ><td colspan='3'>Lote Produccion: " + detail.RcfID + "-" + detail.RcfCode + ", Jaba: " + detail.LppQuantityJaba  +", Kg: " + detail.WbbNetWeight.toFixed(2) +"</td></tr>"
								/* "<tr><td>Lote Produccion: </td><td>" + detail.RcfID +"</td></tr>" +
								"<tr><td>Jabas: </td><td>" + detail.LppQuantityJaba +"</td></tr>" */
							);

							pwa.document.write(
								// "<tr><td>Productor: </td><td>" + detail.PrdCardName +"</td></tr>" +
								// "<tr><td>Fundo: </td><td>" + detail.FagName + " || "+ detail.FltName + " || "+ detail.FltCodSenasa + "</td></tr>" + 
								"<tr><td>Procedencia: </td><td>" + detail.ZonName + " - " + detail.TypeZonLocationName + "</td></tr>"
							);

							pwa.document.write(
								"<tr><td>Certificaciones: </td><td>" + detail.TcDescription +"</td></tr>",
							);
							pwa.document.write(
								"<tr><td>Posición: </td><td>" + detail.LppPosition +"</td></tr>",
							);
							
							pwa.document.write(
								"<tr><td>Fecha Creación: </td><td>" + detail.PrintCreate +"</td></tr>",
							);

							pwa.document.write(
								"<tr><td>Fecha Impresión: </td><td>" + daten +"</td></tr>",
							);

							pwa.document.write("</table>-------------------------------------------------------------</div>");

							pwa.document.write("</table></div>");
						});

				/* }); */

				

				pwa.document.write("</div></body></html>");
				pwa.document.close();
			},
			tranfer(){
				if(this.selected.length > 2){
					
					this.$fun.alert("Solo sebe seleccionar Max 2 tarimas","warning")
					return;
				}
				if(this.selected[0].IDChangeFsma != this.selected[1].IDChangeFsma){
					
					this.$fun.alert("Estas Tarimas no pertenecen al Mismo intercambio","warning")
					return;
				}

				this.selectedItemsSend.push(this.selected[0]);
				this.selectedItemsSendTwo.push(this.selected[1]);

				this.$fun.alert("¿Quieres cambiar los pallet?","question")
				 .then((r) => {
					if (r.value) {
						_sSupplyPalletMirror.save({
							PalletsOne: this.selectedItemsSend,
							PalletsTwo: this.selectedItemsSendTwo,
							FsAction: 2
							}, this.$fun.getUserID())
						.then((r) => {
							this.itemsSend =  [];
							this.itemsSendTwo = [];
							this.selectedItemsSend = [];

							this.$fun.alert("Pallet cambiado correctamente","success");
						});
					}
				});

			},
			loadChanges(){
				console.log("montado vue");
				_sSupplyPalletMirror.list(this.filter, this.$fun.getUserID())
				.then((r) => {
					if (r.status == 200) {
						console.log(" r.data", r.data);
						this.itemsSendEnd = r.data;
					}
				});
			}
        },
        mounted(){
           this.loadChanges();
        }

    }

</script>